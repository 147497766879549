<template>
  <toast/>
  <confirm-dialog/>
  <router-view/>
</template>

<script>
export default {
  name: "Base"
}
</script>
